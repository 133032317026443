<template>
  <div>
    <Content>
      <div class="main-body">
        <Form
          class="formValidate"
          ref="formValidate"
          :rules="ruleValidate"
          label-position="left"
          :label-width="155"
        >
          <div class="action-area">
            <div class="actBtn cancle" @click="cancel">
              <i class="iconfont iconicon-quxiao"></i>
              <span>取消</span>
            </div>
            <div class="actBtn cancle" @click="addDevice">
              <i class="iconfont iconicon-zengjia"></i>
              <span>增加</span>
            </div>
            <div class="actBtn save" @click="handleSubmit('formValidate')">
              <i class="iconfont iconicion-baocun"></i>
              <span>保存</span>
            </div>
          </div>
          <div
            class="basicInfo"
            v-for="(item, index) in formValidate"
            :key="index"
          >
            <div
              class="infoTitle"
              style="border-bottom: 1px solid #DFE3E8;padding-bottom: 10px;"
            >
              基础信息
              <span
                v-show="index != 0"
                class="iconfont iconicon-jianshao"
                style="cursor:pointer"
                @click="removeDevice(index)"
              ></span>
            </div>
            <div class="infoDetail">
              <FormItem>
                <Row>
                  <Col span="8">
                    <FormItem prop="ztdDeviceNo" label="设备编号">
                      <Input
                        v-model="item.ztdDeviceNo"
                        placeholder="请输入"
                        style="width:200px;"
                      ></Input>
                    </FormItem>
                  </Col>
                  <Col span="8">
                    <FormItem prop="ztdDeviceType" label="设备类型">
                      <Input
                        v-model="item.ztdDeviceType"
                        maxlength="20"
                        placeholder="请输入"
                        style="width:200px;"
                      ></Input>
                    </FormItem>
                  </Col>
                  <Col span="8">
                    <FormItem prop="ztdMachineModelName" label="设备型号">
                      <Input
                        v-model="item.ztdMachineModelName"
                        maxlength="20"
                        placeholder="请输入"
                        style="width:200px;"
                      ></Input>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span="8">
                    <FormItem prop="ztdMachineNo" label="机台号">
                      <Input
                        v-model="item.ztdMachineNo"
                        maxlength="10"
                        placeholder="请输入"
                        style="width:200px;"
                      ></Input>
                    </FormItem>
                  </Col>
                  <Col span="8">
                    <FormItem prop="ztdWorkshopId" label="所属车间">
                      <Select v-model="item.ztdWorkshopId" style="width:200px;">
                        <Option
                          v-for="(item, index) in workshops"
                          :value="item.workshopId"
                          :key="index"
                          >{{ item.workshop }}</Option
                        >
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span="8">
                    <FormItem prop="ztdMachineBrand" label="品牌">
                      <Input
                        v-model="item.ztdMachineBrand"
                        placeholder="请输入"
                        style="width:200px;"
                      ></Input>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span="8">
                    <FormItem label="入厂日期">
                      <DatePicker
                        type="date"
                        placeholder="请选择日期"
                        v-model="item.ztdFactoryDate"
                      ></DatePicker>
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </div>
          </div>
        </Form>
      </div>
    </Content>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formValidate: [
        {
          //  ztdId:11,    // 设备ID
          ztdMachineNo: "", // 机台号
          ztdDeviceNo: "", // 设备编号
          ztdDeviceType: "", // 设备类型
          ztdMachineModelName: "", // 机型
          ztdWorkshopId: '', // 车间ID
          ztdMachineBrand: "", // 品牌
          ztdFactoryDate: "" // 入厂日期
        }
      ],
      ruleValidate: {
        ztdDeviceNo: [{ required: true, message: " ", trigger: "blur" }],
        ztdMachineNo: [{ required: true, message: " ", trigger: "blur" }],
        ztdDeviceType: [{ required: true, message: " ", trigger: "blur" }],
        ztdMachineModelName: [
          { required: true, message: " ", trigger: "blur" }
        ],
        ztdWorkshopId: [{ required: true, message: " ", trigger: "blur" }],
        ztdMachineBrand: [{ required: true, message: " ", trigger: "blur" }]
      },
      workshops: [],
      deviceList: []
    };
  },
  methods: {
    // 点击取消按钮返回上一页
    cancel() {
      this.$router.go(-1); // 返回上一层
    },
    // 获取车间列表
    getWorkshops() {
      const that = this;
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/workshoplist",
        method: "get",
        params: {}
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.workshops = res.data.body.workshoplist;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addDevice() {
      const obj = {
        ztdMachineNo: "", // 机台号
        ztdDeviceNo: "", // 设备编号
        ztdDeviceType: "", // 设备类型
        ztdMachineModelName: "", // 机型
        ztdWorkshopId: 22, // 车间ID
        ztdWorkshopName: "", // 车间名称
        ztdMachineBrand: "", // 品牌
        ztdFactoryDate: "" // 入厂日期
      };
      this.formValidate.push(obj);
    },
    removeDevice(index) {
      this.formValidate.splice(index, 1);
    },
    // 获取机台号列表
    getDeviceList() {
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/machineNoList",
        method: "get",
        params: {}
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.deviceList = res.data.body.machineNoList;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 表单验证
    handleSubmit() {
      for (let i = 0; i < this.formValidate.length; i++) {
        if (this.formValidate[i].ztdDeviceNo === "") {
          this.$Message.warning("请填写第" + (i + 1) + "台设备的设备编号");
          return false;
        } else if (this.formValidate[i].ztdDeviceType === "") {
          this.$Message.warning("请填写第" + (i + 1) + "台设备的设备类型");
          return false;
        } else if (this.formValidate[i].ztdMachineModelName === "") {
          this.$Message.warning("请填写第" + (i + 1) + "台设备的设备型号");
          return false;
        } else if (this.formValidate[i].ztdMachineNo === "") {
          this.$Message.warning("请填写第" + (i + 1) + "台设备的机台号");
          return false;
        } else if (this.formValidate[i].ztdWorkshopId === "") {
          this.$Message.warning("请填写第" + (i + 1) + "台设备的所属车间");
          return false;
        } else if (this.formValidate[i].ztdMachineBrand === "") {
          this.$Message.warning("请填写第" + (i + 1) + "台设备的品牌");
          return false;
        }
      }
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/addDevice",
        method: "post",
        data: this.formValidate
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$router.push({
              path: "/deviceManage/check"
            });
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  created() {},
  mounted() {
    this.getWorkshops();
    // this.getDeviceList();
  }
};
</script>

<style lang="scss" scoped>
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .formValidate {
    .action-area {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .actBtn {
        margin: 0px 10px 30px 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: rgba(99, 115, 129, 1);
        cursor: pointer;
      }
      .actBtn :hover {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #2980b9;
        cursor: pointer;
      }
      .cancle {
      }
      .save {
      }
    }
    .basicInfo {
      .infoTitle {
        font-size: 16px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
      }
      .infoDetail {
        .ivu-form-item {
          // margin:10px 0;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          margin-left: 0px !important;
          min-height: 56px;
          line-height: 56px;
          input {
            border: none;
          }
          .ivu-select-selection {
            border: none;
          }
          .ivu-form-item-error-tip {
            position: absolute;
            top: 100%;
            line-height: 1;
            padding-top: 6px;
            color: #ed4014;
            // right: 95px;
            left: 50px;
            top: 32px;
          }
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          // border-bottom:1px solid #DFE3E8;
          padding: 0 15px;
          line-height: 56px;
          min-height: 56px;
          background: #f5f5f5;
          text-align: left;
          width: 155px;
        }

        .ivu-row {
          border-bottom: 1px solid #dfe3e8;
        }
      }
      // .infoDetail >>> .ivu-form-item >>>.ivu-form-item-content{
      //             margin-left:0px !important;
      //         }
    }
    .otherInfo {
      .infoTitle {
        font-size: 16px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
      }
      .infoDetail {
        // .otherForm{
        .ivu-form-item {
          // margin:10px 0;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          margin-left: 0px !important;
          height: 56px;
          line-height: 56px;
          .textfont {
            height: 34px;
            line-height: 34px;
          }
          input {
            border: none;
          }
          .ivu-select-selection {
            border: none;
          }
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          // border-bottom:1px solid #DFE3E8;
          padding: 0 15px;
          line-height: 56px;
          height: 56px;
          height: 56px;
          background: #f5f5f5;
          text-align: left;
        }
        // }
        .ivu-row {
          border-bottom: 1px solid #dfe3e8;
        }
      }
    }
  }
  // .otherInfo{
  //     .infoTitle{
  //                 font-size:16px;
  //                 font-family:PingFangSC;
  //                 font-weight:500;
  //                 color:rgba(51,51,51,1);
  //             }
  //     .infoDetail{
  //         .otherForm{
  //             .ivu-form-item{
  //                 margin:10px 0;

  //             }
  //             .ivu-form-item >>> .ivu-form-item-content{
  //                 margin-left:20px !important;
  //                 .textfont{
  //                         height:34px;
  //                         line-height: 34px;
  //                 }
  //             }
  //             .ivu-form-item >>> .ivu-form-item-label{
  //                 font-size:16px;
  //                 font-family:PingFangSC;
  //                 font-weight:500;
  //                 color:rgba(51,51,51,1);
  //                 border-bottom:1px solid #DFE3E8;
  //             }
  //         }
  //     }
  // }
}
</style>
